import {useEffect, useState} from 'react';

export const useMenu=(Menu, currentLevel)=>{
    const [menu, setMenu]= useState({menus:[], keys:[]});
    useEffect(()=>{
        const fetchChildren=(childrens, keys=[])=>{
            const m=[];
            for (let iii = 0; iii < childrens.length; iii++) {
                const {key, children, level, ...rest} = childrens[iii];
                if((level & currentLevel) > 0){
                    const withChildren=!!children;
                    keys.push({key, withChildren});
                    let ch=undefined;
                    if(withChildren){
                        ch=fetchChildren(children, keys);
                    }
                    m.push({...rest, key, level, children:ch})
                }
            }
            return m;
        }
        const keys=[];
        const menus=fetchChildren(Menu, keys);
        setMenu({menus, keys});
    }, [Menu, currentLevel]);

    return menu;
}