import { grey } from '@ant-design/colors'
import { Button, Divider, Form, Input, Typography } from 'antd'
import React, { Fragment, useCallback } from 'react'
// import {FaUser, FaLock} from 'react-icons/fa'
import {RiLoginCircleLine, RiUser3Line, RiLockLine} from 'react-icons/ri'
import { useDispatch } from 'react-redux';
import {login} from '../../redux/reducer/auth';
// import second from 'first'
export default ({apps}) => {
    const dispatch=useDispatch();
    const onFinished=useCallback((values)=>{
        dispatch(login({...values, app:apps}));
    }, [])
    return (
        <Fragment>
            <div className={`loginBackground login-${apps.toLowerCase()}`} />
            <div style={{display:'flex',  flexDirection:'row', width:'100vw', height:'100vh', justifyContent:'center', alignItems:'center'}}>
                <Form 
                    title={`Login To ${apps}`} 
                    layout='horizontal' 
                    style={{width:480, border:`solid 1px ${grey[1]}`, paddingInline:16, paddingBlock:24, background:'white'}} 
                    labelAlign='left'
                    onFinish={onFinished}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                >
                    <Typography.Title type='secondary' level={2} style={{textAlign:'center'}}>Login To {apps}</Typography.Title>
                    <Divider style={{margin:'8px 0'}} />
                    <div style={{paddingInline:24}}>
                        <Form.Item name={'user'} label="Username" rules={[{required:true, message:'Username Required'}]}>
                            <Input autoFocus placeholder='Username' prefix={<RiUser3Line  color={grey[2]} />} />
                        </Form.Item>
                        <Form.Item name={'password'} label="Password" rules={[{required:true, message:'Password Required'}]}>
                            <Input.Password placeholder='Password' prefix={<RiLockLine color={grey[2]} />} />
                        </Form.Item>
                        <Button 
                            icon={<RiLoginCircleLine style={{marginInline:5}}/>}
                            block
                            htmlType="submit"
                        >
                            Login
                        </Button>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}