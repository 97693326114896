import React from 'react';
import ReactDOM from 'react-dom/client';
import {store} from './redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'antd/dist/antd.css'; 
import SplashPage from './splash'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.Suspense fallback={<SplashPage />}>
      <React.Fragment>
        <App />
        <ToastContainer newestOnTop autoClose={3000} />
      </React.Fragment>
    </React.Suspense>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
